import React from 'react';
import '../styles/Profile.css';

const Profile = ({ user }) => {
  return (
    <div className="profile">
      <h2>User Profile</h2>
      <p><strong>Name:</strong> {user.name}</p>
      <p><strong>Username:</strong> {user.username}</p>
      <p><strong>Email:</strong> {user.email}</p>
      <p><strong>City:</strong> {user.city}</p>
      <p><strong>Job Title:</strong> {user.jobTitle}</p>
      <p><strong>LinkedIn:</strong> <a href={user.linkedin} target="_blank" rel="noopener noreferrer">{user.linkedin}</a></p>
    </div>
  );
};

export default Profile;