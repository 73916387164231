// src/redux/slices/bidSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../utils/api';

export const fetchBids = createAsyncThunk(
  'bids/fetchBids',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/bids');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createBid = createAsyncThunk(
  'bids/createBid',
  async (bidData, { rejectWithValue }) => {
    try {
      const response = await api.post('/bids', bidData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const acceptBid = createAsyncThunk(
  'bids/acceptBid',
  async (bidId, { rejectWithValue }) => {
    try {
      const response = await api.post(`/bids/${bidId}/accept`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const rejectBid = createAsyncThunk(
  'bids/rejectBid',
  async (bidId, { rejectWithValue }) => {
    try {
      const response = await api.post(`/bids/${bidId}/reject`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const bidSlice = createSlice({
  name: 'bids',
  initialState: {
    items: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBids.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBids.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchBids.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createBid.fulfilled, (state, action) => {
        state.items.push(action.payload);
      })
      .addCase(acceptBid.fulfilled, (state, action) => {
        const index = state.items.findIndex(bid => bid.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(rejectBid.fulfilled, (state, action) => {
        const index = state.items.findIndex(bid => bid.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      });
  },
});

export default bidSlice.reducer;