import React, { useState } from 'react';
import '../styles/AcceptedBids.css';

const AcceptedBids = ({ acceptedBids, onInitiateChat, onMarkComplete, onExtendTimeline, onRateTransaction, onRequestSupport }) => {
  const [extendDays, setExtendDays] = useState({});
  const [ratings, setRatings] = useState({});

  const handleExtendTimeline = (exchangeId) => {
    const days = extendDays[exchangeId];
    if (days && days > 0) {
      onExtendTimeline(exchangeId, days);
      setExtendDays({ ...extendDays, [exchangeId]: '' });
    }
  };

  const handleRateTransaction = (exchangeId) => {
    const rating = ratings[exchangeId];
    if (rating && rating >= 1 && rating <= 5) {
      onRateTransaction(exchangeId, rating);
      setRatings({ ...ratings, [exchangeId]: '' });
    }
  };

  return (
    <div className="accepted-bids">
      <h2>Accepted Bids</h2>
      {acceptedBids.length > 0 ? (
        <ul>
          {acceptedBids.map(exchange => (
            <li key={exchange._id} className="accepted-bid-item">
              <h3>Exchange: {exchange.bidId.bidPerkId.title} for {exchange.bidId.offeredPerkId.title}</h3>
              <p>Exchanging with: {exchange.bidId.bidderId.name}</p>
              <p>Quantity: {exchange.bidId.quantity}x {exchange.bidId.bidPerkId.title}</p>
              <p>Completion Date: {new Date(exchange.completionDate).toLocaleDateString()}</p>
              <p>Logistics: {exchange.logistics}</p>
              <p>Status: {exchange.status}</p>
              <div className="bid-actions">
                <button onClick={() => onInitiateChat(exchange)}>Chat with {exchange.bidId.bidderId.name}</button>
                {exchange.status === 'ongoing' && (
                  <button onClick={() => onMarkComplete(exchange._id)}>Mark Complete</button>
                )}
                {exchange.status === 'ongoing' && (
                  <div className="extend-timeline">
                    <input 
                      type="number" 
                      placeholder="Days" 
                      value={extendDays[exchange._id] || ''}
                      onChange={(e) => setExtendDays({ ...extendDays, [exchange._id]: e.target.value })}
                    />
                    <button onClick={() => handleExtendTimeline(exchange._id)}>Extend Timeline</button>
                  </div>
                )}
                {exchange.status === 'completed' && !exchange.rating && (
                  <div className="rate-transaction">
                    <input 
                      type="number" 
                      placeholder="Rate (1-5)" 
                      min="1" 
                      max="5"
                      value={ratings[exchange._id] || ''}
                      onChange={(e) => setRatings({ ...ratings, [exchange._id]: e.target.value })}
                    />
                    <button onClick={() => handleRateTransaction(exchange._id)}>Rate Transaction</button>
                  </div>
                )}
                <button onClick={() => onRequestSupport(exchange._id)}>Request Support</button>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>No accepted bids</p>
      )}
    </div>
  );
};

export default AcceptedBids;