import React from 'react';
import '../styles/MyBids.css';

const MyBids = ({ userPerks, bids, onAcceptBid, onRejectBid }) => {
  const activeBids = bids.filter(bid => bid.status === 'pending');
  const rejectedBids = bids.filter(bid => bid.status === 'rejected');

  return (
    <div className="my-bids">
      <h2>Bids on My Perks</h2>
      {userPerks.map(perk => (
        <div key={perk._id} className="perk-bids">
          <h3>{perk.title}</h3>
          <p>Estimated Value: ${perk.estimatedValue}</p>
          <h4>Active Bids:</h4>
          {activeBids.filter(bid => bid.offeredPerkId === perk._id).length > 0 ? (
            <ul>
              {activeBids
                .filter(bid => bid.offeredPerkId === perk._id)
                .map(bid => (
                  <li key={bid._id} className="bid-item">
                    <p>Bidder: {bid.bidderId.name}</p>
                    <p>Offered: {bid.quantity}x {bid.bidPerkId.title} (Value: ${bid.bidPerkId.estimatedValue * bid.quantity})</p>
                    <button onClick={() => onAcceptBid(bid)}>Accept</button>
                    <button onClick={() => onRejectBid(bid)}>Reject</button>
                  </li>
                ))}
            </ul>
          ) : (
            <p>No active bids</p>
          )}
        </div>
      ))}

      <h2>Rejected Bids</h2>
      {rejectedBids.length > 0 ? (
        <ul>
          {rejectedBids.map(bid => (
            <li key={bid._id} className="bid-item rejected">
              <p>Bidder: {bid.bidderId.name}</p>
              <p>Offered: {bid.quantity}x {bid.bidPerkId.title} for your {bid.offeredPerkId.title}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No rejected bids</p>
      )}
    </div>
  );
};

export default MyBids;