import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import perkReducer from './slices/perkSlice';
import bidReducer from './slices/bidSlice';
import exchangeReducer from './slices/exchangeSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    perks: perkReducer,
    bids: bidReducer,
    exchanges: exchangeReducer,
  },
});