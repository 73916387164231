import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login, register } from '../redux/slices/authSlice';
import '../styles/Auth.css';

const Auth = () => {
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.auth);

  const [isLogin, setIsLogin] = useState(true);
  const [loginData, setLoginData] = useState({ email: '', password: '' });
  const [signupData, setSignupData] = useState({
    name: '',
    username: '',
    email: '',
    password: '',
    city: '',
    jobTitle: '',
    linkedin: '',
  });

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(login(loginData)).unwrap();
    } catch (err) {
      console.error('Login failed:', err);
    }
  };

  const handleSignupSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(register(signupData)).unwrap();
      // After successful registration, log the user in
      await dispatch(login({
        email: signupData.email,
        password: signupData.password
      })).unwrap();
    } catch (err) {
      console.error('Signup failed:', err);
    }
  };

  const handleLoginChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const handleSignupChange = (e) => {
    setSignupData({ ...signupData, [e.target.name]: e.target.value });
  };

  return (
    <div className="auth-container">
      <h2>{isLogin ? 'Login' : 'Sign Up'}</h2>
      {error && <div className="error-message">{error}</div>}
      
      {isLogin ? (
        <form onSubmit={handleLoginSubmit}>
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={loginData.email}
            onChange={handleLoginChange}
            required
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={loginData.password}
            onChange={handleLoginChange}
            required
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>
      ) : (
        <form onSubmit={handleSignupSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={signupData.name}
            onChange={handleSignupChange}
            required
          />
          <input
            type="text"
            name="username"
            placeholder="Username"
            value={signupData.username}
            onChange={handleSignupChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={signupData.email}
            onChange={handleSignupChange}
            required
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={signupData.password}
            onChange={handleSignupChange}
            required
          />
          <input
            type="text"
            name="city"
            placeholder="City"
            value={signupData.city}
            onChange={handleSignupChange}
          />
          <input
            type="text"
            name="jobTitle"
            placeholder="Job Title"
            value={signupData.jobTitle}
            onChange={handleSignupChange}
          />
          <input
            type="text"
            name="linkedin"
            placeholder="LinkedIn URL"
            value={signupData.linkedin}
            onChange={handleSignupChange}
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Signing up...' : 'Sign Up'}
          </button>
        </form>
      )}
      
      <button onClick={() => setIsLogin(!isLogin)}>
        {isLogin ? 'Need an account? Sign Up' : 'Already have an account? Login'}
      </button>
    </div>
  );
};

export default Auth;