import React, { useState } from 'react';
import '../styles/Marketplace.css';

const Marketplace = ({ perks, userPerks, onBid }) => {
  const [selectedUserPerk, setSelectedUserPerk] = useState({});
  const [bidQuantities, setBidQuantities] = useState({});

  const handleUserPerkChange = (marketPerkId, userPerkId) => {
    setSelectedUserPerk({ ...selectedUserPerk, [marketPerkId]: userPerkId });
    setBidQuantities({ ...bidQuantities, [marketPerkId]: 1 });
  };

  const handleBidQuantityChange = (marketPerkId, quantity) => {
    setBidQuantities({ ...bidQuantities, [marketPerkId]: quantity });
  };

  const handleBid = (marketPerkId) => {
    onBid(marketPerkId, selectedUserPerk[marketPerkId], bidQuantities[marketPerkId]);
  };

  return (
    <div className="marketplace">
      <h2>Perk Marketplace</h2>
      <div className="perk-grid">
        {perks.map((perk) => (
          <div key={perk._id} className="perk-card">
            <h3 className="perk-title">{perk.title}</h3>
            <p className="perk-description">{perk.description}</p>
            <p className="perk-value">Estimated Value: ${perk.estimatedValue}</p>
            <form className="bid-form" onSubmit={(e) => {
              e.preventDefault();
              handleBid(perk._id);
            }}>
              <select 
                onChange={(e) => handleUserPerkChange(perk._id, e.target.value)}
                value={selectedUserPerk[perk._id] || ''}
              >
                <option value="">Select your perk to offer</option>
                {userPerks.map((userPerk) => (
                  <option key={userPerk._id} value={userPerk._id}>
                    {userPerk.title} (${userPerk.estimatedValue})
                  </option>
                ))}
              </select>
              {selectedUserPerk[perk._id] && (
                <input 
                  type="number" 
                  placeholder="Bid quantity" 
                  value={bidQuantities[perk._id] || 1}
                  onChange={(e) => handleBidQuantityChange(perk._id, parseInt(e.target.value))}
                  min="1"
                />
              )}
              <button 
                className="bid-button"
                type="submit"
                disabled={!selectedUserPerk[perk._id]}
              >
                Place Bid
              </button>
            </form>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Marketplace;