import React, { useState } from 'react';
import '../styles/ExchangeConfirmation.css';

const ExchangeConfirmation = ({ bid, currentUser, onSendProposal }) => {
  const [completionDate, setCompletionDate] = useState('');
  const [logistics, setLogistics] = useState('');

  const handleSendProposal = () => {
    onSendProposal({
      ...bid,
      completionDate,
      logistics,
    });
  };

  const isCurrentUserBidder = currentUser._id === bid.bidderId._id;
  const otherUserName = isCurrentUserBidder ? bid.offeredPerkId.userId.name : bid.bidderId.name;

  const transactionDescription = `${isCurrentUserBidder ? "You" : bid.bidderId.name} agree${isCurrentUserBidder ? "" : "s"} to trade ${bid.quantity}x ${bid.bidPerkId.title} for ${isCurrentUserBidder ? "the" : "your"} ${bid.offeredPerkId.title} from ${otherUserName}, to be completed by ${completionDate || "[Date to be set]"}.`;

  return (
    <div className="exchange-confirmation">
      <h2>Exchange Confirmation</h2>
      <div className="form-group">
        <label>Completion Date:</label>
        <input 
          type="date" 
          value={completionDate} 
          onChange={(e) => setCompletionDate(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Logistics:</label>
        <textarea
          value={logistics}
          onChange={(e) => setLogistics(e.target.value)}
          placeholder="Enter the logistics of the exchange..."
        />
      </div>
      <div className="transaction-description">
        <h3>Transaction Summary:</h3>
        <p>{transactionDescription}</p>
        <p>Logistics: {logistics || "[To be determined]"}</p>
      </div>
      <button onClick={handleSendProposal}>Send Proposal</button>
    </div>
  );
};

export default ExchangeConfirmation;