// src/redux/slices/perkSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../utils/api';

export const fetchPerks = createAsyncThunk(
  'perks/fetchPerks',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/perks');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createPerk = createAsyncThunk(
  'perks/createPerk',
  async (perkData, { rejectWithValue }) => {
    try {
      const response = await api.post('/perks', perkData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const perkSlice = createSlice({
  name: 'perks',
  initialState: {
    items: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPerks.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPerks.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchPerks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createPerk.fulfilled, (state, action) => {
        state.items.push(action.payload);
      });
  },
});

export default perkSlice.reducer;