import React, { useState, useEffect } from 'react';
import '../styles/Chat.css';

const Chat = ({ currentUser, otherUser, exchangeId, onSendMessage }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    // Fetch messages for this exchange
    // This should be implemented in the parent component and passed as a prop
    // For now, we'll use an empty array
    setMessages([]);
  }, [exchangeId]);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (newMessage.trim()) {
      const message = {
        senderId: currentUser._id,
        content: newMessage.trim(),
      };
      onSendMessage(exchangeId, message.content);
      setMessages([...messages, message]);
      setNewMessage('');
    }
  };

  return (
    <div className="chat">
      <h2>Chat with {otherUser.name}</h2>
      <div className="message-list">
        {messages.map((message, index) => (
          <div 
            key={index} 
            className={`message ${message.senderId === currentUser._id ? 'sent' : 'received'}`}
          >
            <strong>{message.senderId === currentUser._id ? currentUser.name : otherUser.name}: </strong>
            {message.content}
          </div>
        ))}
      </div>
      <form onSubmit={handleSendMessage}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message..."
        />
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default Chat;