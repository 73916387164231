import React, { useState } from 'react';
import '../styles/MyPerks.css';

const MyPerks = ({ userPerks, onCreatePerk }) => {
  const [newPerk, setNewPerk] = useState({ title: '', description: '', estimatedValue: '' });

  const handleSubmit = (e) => {
    e.preventDefault();
    onCreatePerk(newPerk);
    setNewPerk({ title: '', description: '', estimatedValue: '' });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewPerk(prevPerk => ({
      ...prevPerk,
      [name]: name === 'estimatedValue' ? parseFloat(value) : value
    }));
  };

  return (
    <div className="my-perks">
      <div className="create-perk">
        <h3>Create New Perk</h3>
        <form onSubmit={handleSubmit}>
          <input
            name="title"
            placeholder="Perk Title"
            value={newPerk.title}
            onChange={handleChange}
            required
          />
          <textarea
            name="description"
            placeholder="Description"
            value={newPerk.description}
            onChange={handleChange}
            required
          />
          <input
            type="number"
            name="estimatedValue"
            placeholder="Estimated Value"
            value={newPerk.estimatedValue}
            onChange={handleChange}
            required
          />
          <button type="submit">Create Perk</button>
        </form>
      </div>
      <div className="user-perks">
        <h3>Your Perks</h3>
        {userPerks.length > 0 ? (
          userPerks.map((perk) => (
            <div key={perk._id} className="perk-card">
              <h4>{perk.title}</h4>
              <p>{perk.description}</p>
              <p className="estimated-value">Estimated Value: ${perk.estimatedValue}</p>
            </div>
          ))
        ) : (
          <p>You havent created any perks yet.</p>
        )}
      </div>
    </div>
  );
};

export default MyPerks;