import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Auth from './Auth';
import Marketplace from './Marketplace';
import MyPerks from './MyPerks';
import MyBids from './MyBids';
import AcceptedBids from './AcceptedBids';
import Profile from './Profile';
import ExchangeConfirmation from './ExchangeConfirmation';
import Chat from './Chat';
import '../styles/PerkExchangeMarketplace.css';

// Import actions from Redux slices
import { login, logout, fetchUserProfile } from '../redux/slices/authSlice';
import { fetchPerks, createPerk } from '../redux/slices/perkSlice';
import { fetchBids, createBid, acceptBid, rejectBid } from '../redux/slices/bidSlice';
import { fetchExchanges, createExchange, updateExchange, completeExchange } from '../redux/slices/exchangeSlice';

const PerkExchangeMarketplace = () => {
  const dispatch = useDispatch();
  
  // Redux state
  const { user, isAuthenticated, loading: authLoading } = useSelector((state) => state.auth);
  const { items: perks, loading: perksLoading } = useSelector((state) => state.perks);
  const { items: bids, loading: bidsLoading } = useSelector((state) => state.bids);
  const { items: exchanges, loading: exchangesLoading } = useSelector((state) => state.exchanges);

  // Local state
  const [activeTab, setActiveTab] = useState('marketplace');
  const [selectedBid, setSelectedBid] = useState(null);
  const [activeChat, setActiveChat] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchUserProfile());
      dispatch(fetchPerks());
      dispatch(fetchBids());
      dispatch(fetchExchanges());
    }
  }, [isAuthenticated, dispatch]);

  const handleLogin = async (loginData) => {
    try {
      await dispatch(login(loginData)).unwrap();
    } catch (error) {
      console.error('Login error:', error);
      // Handle login error (e.g., show error message)
    }
  };

  const handleSignup = async (signupData) => {
    try {
      // Implement signup logic here
      // After successful signup, log the user in
      await dispatch(login({ email: signupData.email, password: signupData.password })).unwrap();
    } catch (error) {
      console.error('Signup error:', error);
      // Handle signup error
    }
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleCreatePerk = async (newPerk) => {
    try {
      await dispatch(createPerk(newPerk)).unwrap();
    } catch (error) {
      console.error('Error creating perk:', error);
      // Handle create perk error
    }
  };

  const handleBid = async (marketPerkId, userPerkId, quantity) => {
    try {
      await dispatch(createBid({ offeredPerkId: marketPerkId, bidPerkId: userPerkId, quantity })).unwrap();
    } catch (error) {
      console.error('Error placing bid:', error);
      // Handle bid error
    }
  };

  const handleAcceptBid = async (bid) => {
    try {
      await dispatch(acceptBid(bid.id)).unwrap();
      setSelectedBid(bid);
      setActiveTab('confirmation');
    } catch (error) {
      console.error('Error accepting bid:', error);
      // Handle accept bid error
    }
  };

  const handleRejectBid = async (bid) => {
    try {
      await dispatch(rejectBid(bid.id)).unwrap();
    } catch (error) {
      console.error('Error rejecting bid:', error);
      // Handle reject bid error
    }
  };

  const handleSendProposal = async (proposedExchange) => {
    try {
      await dispatch(createExchange(proposedExchange)).unwrap();
      setSelectedBid(null);
      setActiveTab('accepted-bids');
    } catch (error) {
      console.error('Error sending proposal:', error);
      // Handle send proposal error
    }
  };

  const handleInitiateChat = (exchange) => {
    setActiveChat(exchange);
    setActiveTab('chat');
  };

  const handleSendMessage = async (exchangeId, content) => {
    try {
      // Implement send message logic here
      // This might involve creating a new Redux slice for messages
      console.log('Sending message:', { exchangeId, content });
    } catch (error) {
      console.error('Error sending message:', error);
      // Handle send message error
    }
  };

  const handleMarkComplete = async (exchangeId) => {
    try {
      await dispatch(completeExchange(exchangeId)).unwrap();
    } catch (error) {
      console.error('Error marking exchange as complete:', error);
      // Handle mark complete error
    }
  };

  const handleExtendTimeline = async (exchangeId, days) => {
    try {
      const exchange = exchanges.find(ex => ex.id === exchangeId);
      const newCompletionDate = new Date(exchange.completionDate);
      newCompletionDate.setDate(newCompletionDate.getDate() + days);
      
      await dispatch(updateExchange({ id: exchangeId, completionDate: newCompletionDate.toISOString() })).unwrap();
    } catch (error) {
      console.error('Error extending timeline:', error);
      // Handle extend timeline error
    }
  };

  const handleRateTransaction = async (exchangeId, rating) => {
    try {
      await dispatch(updateExchange({ id: exchangeId, rating })).unwrap();
    } catch (error) {
      console.error('Error rating transaction:', error);
      // Handle rate transaction error
    }
  };

  const handleRequestSupport = (exchangeId) => {
    console.log(`Support requested for exchange ${exchangeId}`);
    // Implement support request logic here
  };

  if (authLoading || perksLoading || bidsLoading || exchangesLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Auth onLogin={handleLogin} onSignup={handleSignup} />;
  }

  return (
    <div className="perk-exchange-marketplace">
      <h1>Employee Perk Exchange</h1>
      <div className="tabs">
        <button onClick={() => setActiveTab('marketplace')}>Marketplace</button>
        <button onClick={() => setActiveTab('my-perks')}>My Perks</button>
        <button onClick={() => setActiveTab('my-bids')}>My Bids</button>
        <button onClick={() => setActiveTab('accepted-bids')}>Accepted Bids</button>
        <button onClick={() => setActiveTab('profile')}>Profile</button>
        <button onClick={handleLogout}>Logout</button>
      </div>
      {activeTab === 'marketplace' && <Marketplace perks={perks} userPerks={perks.filter(p => p.userId === user.id)} onBid={handleBid} />}
      {activeTab === 'my-perks' && <MyPerks userPerks={perks.filter(p => p.userId === user._id)} onCreatePerk={handleCreatePerk} />}
      {activeTab === 'my-bids' && <MyBids userPerks={perks.filter(p => p.userId === user.id)} bids={bids} onAcceptBid={handleAcceptBid} onRejectBid={handleRejectBid} />}
      {activeTab === 'accepted-bids' && (
        <AcceptedBids 
          acceptedBids={exchanges}
          onInitiateChat={handleInitiateChat}
          onMarkComplete={handleMarkComplete}
          onExtendTimeline={handleExtendTimeline}
          onRateTransaction={handleRateTransaction}
          onRequestSupport={handleRequestSupport}
        />
      )}
      {activeTab === 'profile' && <Profile user={user} />}
      {activeTab === 'confirmation' && selectedBid && (
        <ExchangeConfirmation
          bid={selectedBid}
          currentUser={user}
          onSendProposal={handleSendProposal}
        />
      )}
      {activeTab === 'chat' && activeChat && (
        <Chat
          currentUser={user}
          otherUser={activeChat.bidId.bidderId._id === user._id ? activeChat.bidId.offeredPerkId.userId : activeChat.bidId.bidderId}
          exchangeId={activeChat._id}
          onSendMessage={handleSendMessage}
        />
      )}
    </div>
  );
};

export default PerkExchangeMarketplace;