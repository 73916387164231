// src/redux/slices/exchangeSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../utils/api';

export const fetchExchanges = createAsyncThunk(
  'exchanges/fetchExchanges',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/exchanges');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createExchange = createAsyncThunk(
  'exchanges/createExchange',
  async (exchangeData, { rejectWithValue }) => {
    try {
      const response = await api.post('/exchanges', exchangeData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateExchange = createAsyncThunk(
  'exchanges/updateExchange',
  async ({ id, ...updateData }, { rejectWithValue }) => {
    try {
      const response = await api.put(`/exchanges/${id}`, updateData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const completeExchange = createAsyncThunk(
  'exchanges/completeExchange',
  async (exchangeId, { rejectWithValue }) => {
    try {
      const response = await api.post(`/exchanges/${exchangeId}/complete`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const exchangeSlice = createSlice({
  name: 'exchanges',
  initialState: {
    items: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExchanges.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchExchanges.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchExchanges.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createExchange.fulfilled, (state, action) => {
        state.items.push(action.payload);
      })
      .addCase(updateExchange.fulfilled, (state, action) => {
        const index = state.items.findIndex(exchange => exchange.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(completeExchange.fulfilled, (state, action) => {
        const index = state.items.findIndex(exchange => exchange.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      });
  },
});

export default exchangeSlice.reducer;